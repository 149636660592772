<template>
  <div class="mb-50">
    <div class="list-z">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
        <el-breadcrumb-item :to="{ path: '/engineering/index' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/creative-company/index' }"
          >造价公司</el-breadcrumb-item
        >
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main" v-loading="loading">
      <el-row type="flex" align="middle" style="margin-bottom: 68px">
        <el-col :span="24">
          <div class="a-flex flex-between mb-20">
            <div class="title">{{ model.fullName || "-" }}</div>
            <div class="a-flex">
              <div class="bor-txt">信用中国</div>
              <div class="bor-txt ml-20">中标项目</div>
              <div class="bor-txt ml-20">全国信用分</div>
              <div class="bor-txt ml-20">全国备案</div>
            </div>
          </div>
          <!--<div class="txt-r">
            【阅读次数：<span>{{ model.txt2 }}</span> 】
          </div>-->
          <div class="line" style="padding: 15px 0px"></div>
        </el-col>
      </el-row>
      <el-row class="mb-40">
        <div>
          <el-col :span="12">
            <div>
              <div class="infor_mation">
                <div class="mb-40">
                  统一社会信用代码：<span>{{ model.unicode || "-" }}</span>
                </div>
                <div>
                  企业注册属地：<span>{{ model.city || "-" }}</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="infor_mation">
              <div class="mb-40">
                企业法定代表人：<span>{{ model.legalPerson || "-" }}</span>
              </div>
              <div>
                企业经营地址：<span>{{ model.address || "-" }}</span>
              </div>
            </div>
          </el-col>
        </div>
      </el-row>
      <el-row class="infor_mation mb-80">
        <el-col :span="3">企业登记注册类型：</el-col>
        <el-col :span="21">
          <div class="wrpper">
            <input id="exp1" class="exp" type="checkbox" />
            <div class="text">
              <label class="btn" for="exp1"></label>
              {{ model.scope || "-" }}
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <div>
          <el-tabs
            type="card"
            stretch
            v-model="activeTab"
            @tab-click="handleTabClick"
          >
            <el-tab-pane label="企业资质资格" name="tab1">
              <generic-table
                :get-data="getPageDataQualification"
                :columns="displayColumnsQualification"
                :page-size="size"
                :hide-pagination="false"
                :headerCellBkcolor="'#F8FDFF'"
                @linkAction="onLinkAction"
              ></generic-table>
            </el-tab-pane>
            <el-tab-pane label="注册人员" name="tab2">
              <generic-table
                :get-data="getPageDataPerson"
                :columns="displayColumnsPerson"
                :page-size="size"
                :hide-pagination="false"
                :headerCellBkcolor="'#F8FDFF'"
                @linkAction="onLinkAction"
              ></generic-table>
            </el-tab-pane>
            <el-tab-pane label="工程项目" name="tab3">
              <generic-table
                :get-data="getPageDataProject"
                :columns="displayColumnsProject"
                :page-size="size"
                :hide-pagination="false"
                :headerCellBkcolor="'#F8FDFF'"
                @linkAction="onLinkAction"
              ></generic-table>
            </el-tab-pane>
            <el-tab-pane label="不良行为" name="tab4"> </el-tab-pane>
            <el-tab-pane label="良好行为" name="tab5"> </el-tab-pane>
            <el-tab-pane label="黑名单记录" name="tab6"> </el-tab-pane>
            <el-tab-pane label="失信联合惩戒记录" name="tab7"> </el-tab-pane>
            <el-tab-pane label="变更记录" name="tab8"> </el-tab-pane>
          </el-tabs>
        </div>
      </el-row>
    </div>
  </div>
</template>
<script>
import GenericTable from "@/components/generic-table";
import { getEnterpriseHighDetail } from "@/api/engineering";
import {
  getDcEnterpriseDetail,
  getEnterprisePersonPage,
  getEnQualificationPage,
  getEnterpriseProjectInfoPage,
} from "@/api/aptitude";
export default {
  components: {
    GenericTable,
  },
  data() {
    return {
      activeTab: "tab1",
      size: 20,
      current: 1,
      model: {
        txt2: "111111",
      },
      // 企业资质资格
      displayColumnsQualification: [
        {
          label: "资质类别",
          prop: "typeName",
          minWidth: 25,
        },
        {
          label: "资质证书号",
          prop: "certno",
          minWidth: 25,
        },
        { label: "资质名称", prop: "name", minWidth: 20 },
        { label: "发证日期", prop: "startdate", minWidth: 20 },
        { label: "发证有效期", prop: "enddate", minWidth: 20 },
        { label: "发证机关", prop: "dummyField", minWidth: 20 },
        { label: "预览", prop: "preview", minWidth: 20 },
      ],
      // 注册人员
      displayColumnsPerson: [
        {
          label: "人员姓名",
          prop: "name",
          minWidth: 13,
        },
        { label: "性别", prop: "sex", minWidth: 13 },
        { label: "身份证号", prop: "no", minWidth: 13 },
        {
          label: "注册号",
          prop: "code",
          minWidth: 13,
        },
        { label: "注册类别", prop: "typeName", minWidth: 13 },
        { label: "唯一号", prop: "keyno", minWidth: 13 },
      ],
      // 工程项目
      displayColumnsProject: [
        {
          label: "项目名称",
          prop: "prjname",
          minWidth: 25,
        },
        {
          label: "项目编号",
          prop: "prjnum",
          minWidth: 25,
        },
        { label: "项目类别", prop: "prjtypenum", minWidth: 20 },
        { label: "建设单位", prop: "buildcorpname", minWidth: 20 },
      ],
      loading: false
    };
  },
  mounted(e) {
    this.getEnterpriseHighDetail(this.$route.query.unicode);
  },
  methods: {
    async getEnterpriseHighDetail(unicode) {
      this.loading = true
      const param = {
        unicode: unicode,
      };
      // 获取API
      const res = await getEnterpriseHighDetail(param);
      this.model = res.data;
      this.loading = false
    },

    handleTabClick(tab) {
    },
    onLinkAction(prop, row) {
      //   if (prop == "fullName") {
      //     this.$router.push({path: "/qualification/detail/", query: { unicode: row.unicode }});
      //   }
    },
    // 企业资质资格
    async getPageDataQualification({ size, current }) {
        this.size = size;
        this.current = current;
        const unicode = this.$route.query.unicode || this.unicode;
        // 获取数据的 API 请求
        const response = await getEnQualificationPage({
          size,
          current,
          unicode,
        });
        let records = response.data ? response.data.records : [];
        let total = response.data ? response.data.total : 0;
        (records || []).forEach((item) => {
          item.preview = item.certno ? "证书信息" : "";
        });
        return {
          data: records,
          total: total,
        };
    },
    //注册人员
    async getPageDataPerson({ size, current }) {
      this.size = size;
      this.current = current;
      const unicode = this.$route.query.unicode || this.unicode;
      // 获取数据的 API 请求
      const response = await getEnterprisePersonPage({
          size,
          current,
          unicode,
      });
      let records = response.data ? response.data.records : [];
      let total = response.data ? response.data.total : 0;
      (records || []).forEach((item) => {
          item.profsName = item.profs ? (
              Array.isArray(item.profs) ?  item.profs.join(",") : this.jsonDecodeProfs(item.profs)
          ) : '';
      });
      return {
          data: records,
          total: total,
      };
    },
    //工程项目
    async getPageDataProject({ size, current }) {
        this.size = size;
        this.current = current;
        const unicode = this.$route.query.unicode || this.unicode;
        // 获取数据的 API 请求
        const response = await getEnterpriseProjectInfoPage({
          size,
          current,
          unicode,
        });
        let records = response.data ? response.data.records : [];
        let total = response.data ? response.data.total : 0;
        // (records || []).forEach((item) => {
        //     item.preview = item.certno ? '证书信息' : '';
        // });
        return {
          data: records,
          total: total,
        };
    },
  },
};
</script>
<style scoped>
.a-flex {
  display: flex;
}
/* 上下左右 */
.flex-between {
  justify-content: space-between;
  align-items: center;
}
/* 垂直居中 */
.flex-center {
  align-items: center;
}
.main {
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: #4e4e4e;
}
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.fs-18 {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px;
}
.line {
  border-bottom: 1px solid #dcdcdc;
}
.title {
  font-size: 24px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #212121;
}
.bor-txt {
  height: 32px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #1890ff;
  font-size: 18px;
  color: #1890ff;
  line-height: 31px;
  padding: 0px 12px;
}
.txt-r {
  font-size: 14px;
  text-align: right;
}
.infor_mation {
  text-align: left;
  font-size: 16px;
}
/* 文字展开/收起 */
.wrpper {
  display: flex;
  overflow: hidden;
}
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  position: relative;
  line-height: 1.8;
  max-height: 3.5em;
  transition: 0.3s max-height;
}
.text::before {
  content: "";
  height: calc(100% - 26px);
  float: right;
}
.text::after {
  content: "";
  width: 999vw;
  height: 999vw;
  position: absolute;
}
.btn {
  position: relative;
  float: right;
  clear: both;
  margin-left: 30px;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
}
.btn::after {
  content: "展开";
  color: #007ef2;
}
.exp {
  display: none;
}
.exp:checked + .text {
  max-height: none;
}
.exp:checked + .text::after {
  visibility: hidden;
}
.exp:checked + .text .btn::before {
  visibility: hidden;
}
.exp:checked + .text .btn::after {
  content: "收起";
}
.btn::before {
  content: "...";
  position: absolute;
  left: -16px;
  transform: translateX(-100%);
}
/* ---------------- */
::v-deep .el-tabs__nav {
  background-color: #f8f8f8;
}
::v-deep .el-tabs__item.is-active {
  background-color: #ffffff;
}
</style>